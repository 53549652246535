import React, {useEffect, useRef} from 'react';
import { 
  Box, 
  Flex, 
  Image, 
  Input, 
  Text, 
  CloseButton, 
  IconButton, 
  Tooltip,   
  Textarea,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import { ChevronUpIcon } from '@chakra-ui/icons'
import MathJax from '../MathJax';
import './AIChat.scss';
import CustomModal from '../Modal';

export const AIChat = ({ 
  chatLoading, 
  chat, 
  toggleAIChat, 
  toggleChat, 
  followUpQuestions = [], 
  askFollowUPQuestion, 
  onReview, 
  openModal, 
  closeModal, 
  regenerate, 
  feedback, 
  setFeedback,
  submitFeedback,
  feedbackResponse
}) => {

  const chatRef = useRef()
 
  const initialRef = React.useRef(null)

  useEffect(() => {
    if(followUpQuestions.length > 0 && !toggleChat) {
      const height = chatRef?.current?.querySelector(".chat-input-wrap").offsetHeight
      document.querySelector(".chat-body").style.height = `calc(100% - ${height + 60}px)`
    }
  }, [followUpQuestions, toggleChat])

  return (
    <>
      <Box className="chat-container" ref={chatRef}>
        {
          !toggleChat ?
            <>
              <Box className="chat-header">
                <Flex alignItems="center">
                  <Image className="profile-pic" src="/images/ninja.png" />
                  <Box className="chat-info">
                    <Box className="chat-with">Ask AI Ninja</Box>
                    <Box className="chat-disclaimer">AI Ninja can make mistakes. Please verify solution.</Box>
                  </Box>
                </Flex>
                <CloseButton size='lg' onClick={toggleAIChat} />
              </Box>
              <Box className="chat-body">
                {
                  chat.length > 0 ?
                  chat.map((c, index) => 
                    <>
                      <Flex justifyContent={"flex-start"}>
                        <MathJax mathData={c.question} id={`${index}+10`} className="message sent"/>
                      </Flex>
                      <Box>
                        <Flex justifyContent={"flex-end"}>
                          {chatLoading && c.response === "" ?
                            <Image src="/images/chat-loading.gif" /> :
                            <MathJax mathData={c.response} id={index} className="message received"/>
                          }
                        </Flex>
                        {
                          c.response && (chat.length === index + 1) ? 
                          <Flex justifyContent={"flex-end"}>
                            <Tooltip label='Like'>
                              {
                                c.like ? 
                                <Image src="/images/thumbs-up-dark.png" width="24px" height="24px" sx={{_hover: ""}} onClick={() => onReview(false, false, index)}/> :
                                <Image src="/images/thumbs-up.png" width="24px" height="24px" sx={{_hover: ""}} onClick={() => onReview(true, false, index)}/>
                              }
                            </Tooltip>
                            <Tooltip label='Dislike'>
                              {
                                c.dislike ? 
                                <Image src="/images/thumbs-down-dark.png" width="24px" height="24px" sx={{_hover: ""}} onClick={() => onReview(false, false, index)} marginLeft="10px"/> :
                                <Image src="/images/thumbs-down.png" width="24px" height="24px" sx={{_hover: ""}} onClick={() => onReview(false, true, index)} marginLeft="10px"/>
                              }
                            </Tooltip>
                            <Tooltip label='Regenrate the response'>
                              <Image src="/images/regenerate.png" width="24px" height="24px" marginLeft="10px" onClick={() => regenerate(c.question)}/>
                            </Tooltip>
                          </Flex> : 
                          c.response && (chat.length !== index + 1) ?
                          <Flex justifyContent={"flex-end"}>
                              {
                                c.like ?
                                  <Image src="/images/thumbs-up-dark.png" width="24px" height="24px" /> :
                                c.dislike ?
                                  <Image src="/images/thumbs-down-dark.png" width="24px" height="24px"  marginLeft="10px"/> : <></>
                              }
                          </Flex> : <></>
                        }
                      </Box>
                    </>
                  ) : <Text color="#ccc">No Chat</Text>
                }
              </Box>
              <Box className="chat-input-wrap">
                {
                  followUpQuestions === "Reached Maximum Limit" ? 
                  <></> :
                  followUpQuestions.length > 0 ?
                    <>
                      <Box className={`${chatLoading ? 'chat-disable' : 'chat-input'}`}>
                        <Input
                          onClick={(e) => askFollowUPQuestion(e.target.value)}
                          type='radio'
                          name={followUpQuestions[0]}
                          value={followUpQuestions[0]}
                        />{followUpQuestions[0]}
                      </Box>
                      <Box className={`${chatLoading ? 'chat-disable' : 'chat-input'}`}>
                        <Input
                          onClick={(e) => askFollowUPQuestion(e.target.value)}
                          type='radio'
                          name={followUpQuestions[1]}
                          value={followUpQuestions[1]}
                        />{followUpQuestions[1]}
                      </Box>
                    </> : <></>
                }
              </Box>
            </> :
            <Box className="chat-header-close">
              <Flex alignItems="center">
                <Image className="profile-pic" src="/images/ninja.png" />
                <Box className="chat-info">
                  <Box className="chat-with">Ask AI Ninja</Box>
                  <Box className="chat-disclaimer">AI Ninja can make mistakes. Please verify solution.</Box>
                </Box>
              </Flex>
              <IconButton
                variant='ghost'
                aria-label='Call Sage'
                fontSize='20px'
                icon={<ChevronUpIcon />}
                onClick={toggleAIChat}
              />
            </Box>
        }
        <CustomModal openModal={openModal} closeModal={closeModal} heading={"Submit feedback to NinjaLearner"} submitFeedback={submitFeedback} feedbackResponse={feedbackResponse}>
            <FormControl>
              <FormLabel fontSize='15px' fontFamily='Open Sans'>What went wrong?</FormLabel>
              <Textarea as="textarea" ref={initialRef} placeholder='Give as much detail as you can.' value={feedback} onChange={(e) => setFeedback(e.target.value)}/>
            </FormControl>
        </CustomModal>
      </Box>
    </>
  );
}
