import React from "react";
import { Box, Flex, Image, Button } from "@chakra-ui/react";
import { Viewer } from "../../components/Viewer";
import { useQViewer } from "../QViewerPage/useQViewer";
import { QSetHeader } from "../../components/QSetHeader";
import { AIChat } from "../../components/AIChat";


export const QViewerReviewPage = ({ user, loadingCallback, loading }) => {
  const {
    selectedDyno,
    qSetId,
    requestStores,
    lastAttemptedDynoId,
    submissionCallback,
    dynoLoading,
    currentQSetDynoIndex,
    onChangeDyno,
    navigateToHome,
    disableNext,
    disablePrev,
    askAI,
    chatLoading,
    toggleChat,
    toggleAIChat,
    followUpQuestions,
    chat,
    askFollowUPQuestion,
    askFirstQuestion,
    hideFirstQuestionBtn,
    onReview,
    regenerate,
    openModal,
    closeModal,
    feedback,
    setFeedback,
    submitFeedback,
    feedbackResponse
  } = useQViewer({ user, loadingCallback, isReview: true });
  const min = Math.floor(selectedDyno?.mySubmission?.timeTaken / 60);
  const sec = selectedDyno?.mySubmission?.timeTaken - min * 60;
  return (
    <>
      <QSetHeader
        onChangeDyno={onChangeDyno}
        navigateToHome={navigateToHome}
        disableNext={disableNext}
        disablePrev={disablePrev}
      />
      {dynoLoading && (
        <Box className="overlay-loader">
          <Image src="/images/ninja.gif" width="100px" height= "100px"/>
        </Box>
      )}
      <Flex width="100%" className="review-page parent-wrap">
        {!loading && !dynoLoading && (
          <Box
            className={
              selectedDyno.myDyno &&
              selectedDyno.myDyno.info.dyno_type === "Practice"
                ? "practice-dyno"
                : "common-dyno"
            }
          >
            {selectedDyno?.myDyno?._id && (
              <Viewer
                selectedDyno={selectedDyno}
                requestStores={requestStores}
                userInfo={user}
                submissionCallback={submissionCallback}
                lastAttemptedDynoId={lastAttemptedDynoId}
                qSetId={qSetId}
              />
            )}
            {!hideFirstQuestionBtn ? <Button onClick={askFirstQuestion} variant="outline" colorScheme='blue' sx={{display: "block", margin: "0 auto 80px"}}>For more clarification ask AI Ninja</Button> : <></>}
          </Box>
        )}
        <Box className="timer-chat-box">
          <Box className="timer-chat-wrap">
            {!loading && !dynoLoading && 
              <>
                <Box className="info-container align-start">
                  <Box className="info-wrapper flex-center padding-10">
                    <Box>
                      Question no.{" "}
                      <span
                        style={{
                          fontSize: "30px",
                          color: "#00b0ff",
                          fontWeight: 600,
                        }}
                      >
                        {currentQSetDynoIndex}
                      </span>
                      /10
                    </Box>
                    <Box className="time-taken">
                      Time taken:{" "}
                      <span className="timer-text">{min}</span> Min{" "}
                      <span className="timer-text">{sec}</span> Sec
                    </Box>
                  </Box>
                </Box>
                <Box className={`${toggleChat ? "info-container align-start close-chat" : "info-container align-start chat small-screen"} `}>
                  <Box className="info-wrapper">
                    <AIChat 
                      askAI={askAI} 
                      chatLoading={chatLoading} 
                      chat={chat} 
                      toggleChat={toggleChat} 
                      toggleAIChat={toggleAIChat} 
                      followUpQuestions={followUpQuestions} 
                      askFollowUPQuestion={askFollowUPQuestion}
                      onReview={onReview}
                      openModal={openModal}
                      closeModal={closeModal}
                      regenerate={regenerate}
                      feedback={feedback}
                      setFeedback={setFeedback}
                      submitFeedback={submitFeedback}
                      feedbackResponse={feedbackResponse}
                      />
                  </Box>
                </Box>
              </>
            }
          </Box>
        </Box>
      </Flex>
    </>
  );
};
