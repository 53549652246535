import { useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useLocation, useHistory } from "react-router-dom/";
import { DYNO, DYNO_REVIEW, QSET, REQUESTSTORES, AICHAT, QUESTIONTAG } from "../../graphql/queries";
import { SENDFEEDBACKFORBADRESPONSE } from "../../graphql/mutations";
import { getDefaultValue } from "../../utils/helper";

export const useQViewer = ({ user, loadingCallback, isReview = false }) => {
  const [selectedDyno, setSelectedDyno] = useState({});
  const [selectedDynoId, setSelectedDynoId] = useState("");
  const [requestStores, setRequestStores] = useState({
    constants: "",
    functions: "",
    patterns: "",
    numberings: "",
    statuses: "",
    types: "",
    rubrics: "",
  });
  const [dynoIdList, setDynoIdList] = useState([]);
  const [lastAttemptedDynoId, setLastAttemptedDynoId] = useState("");
  const [qSetId, setQSetId] = useState("");
  const [dynoLoading, setDynoLoading] = useState(false);
  const [currentQSetDynoIndex, setCurrentQSetDynoIndex] = useState(false);
  const [disableNext, setDisableNext] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [isLastDynoOfQSet, setIsLastDynoOfQSet] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [chat, setChat] = useState([])
  const [toggleChat, setToggleChat] = useState(true);
  const [followUpQuestions, setFollowUpQuestions] = useState([]);
  const [response, setResponse] = useState("");
  const [hideFirstQuestionBtn, setHideFirstQuestionBtn] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [feedback, setFeedback] = useState("")
  const [feedbackResponse, setFeedbackResponse] = useState({})
  const [hasHiddenObjects, setHasHiddenObjects] = useState(false)

  const history = useHistory();
  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const qsetid = queryParams.get("qsetid");
  const appid = queryParams.get("appid");
  const userid = queryParams.get("userid");

  const closeModal = () => {
    setOpenModal(false)
  }

  const [getRequestStores] = useLazyQuery(REQUESTSTORES, {
    onCompleted: (data) => {
      setRequestStores({
        constants: { constants: data.constants },
        functions: {
          functions: data.functions.map(
            ({
              id: _id,
              name,
              example,
              space,
              description,
              arguments: params,
            }) => ({
              _id,
              name,
              example,
              space,
              description,
              arguments: params,
            }),
          ),
        },
        patterns: {
          patterns: data.patterns.map(
            ({ id: _id, patternName: name, patternCode: pattern }) => ({
              id: _id,
              name: name,
              pattern: pattern,
            }),
          ),
        },
        units: {
          units: data.numberings.map(({ id: _id, name, units }) => ({
            id: _id,
            name: name,
            units: units,
          })),
        },
        statuses: { statuses: data.dynoStatuses },
        types: { types: data.dynoTypes },
        rubrics: { rubrics: [] },
      });
    },
  });

  const [getQuestionTags] = useLazyQuery(QUESTIONTAG, {
    onCompleted: (data) => {
      setHasHiddenObjects(data.questionNode.questionTags.map(d => d.name).includes("hidden objects"))
    },
  })

  const [getDyno] = useLazyQuery(isReview ? DYNO_REVIEW : DYNO, {
    onCompleted: (data) => {
      const dyno = data.dyno;
      getQuestionTags({
        variables: {
          where: {
            questionVariableId: {
              id: {
                equals: dyno.variables.find(v => v.title.includes("question")).id
              }
            }
          }
        }
      })
      if(dyno.id === dynoIdList[dynoIdList.length - 1].id) {
        setIsLastDynoOfQSet(true)
    }
      let { myDyno, myFields, myGrids, mySubmission } = getDynoData(dyno);
      setSelectedDyno({
        myDyno: myDyno,
        myFields: myFields,
        myGrids: myGrids,
        mySubmission: isReview ? mySubmission : [],
      });
      const dynoIndex = dynoIdList.findIndex(
        (lst) => lst.id === selectedDynoId,
      );
      if (dynoIndex === 0) {
        setDisablePrev(true);
      } else {
        setDisablePrev(false);
      }
      if (dynoIndex === dynoIdList.length - 1) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
      }
      setCurrentQSetDynoIndex(dynoIndex + 1);
      loadingCallback(false, "");
      setDynoLoading(false);
    },
    fetchPolicy: "cache-and-network",
  });

  const [getQSet] = useLazyQuery(QSET, {
    onCompleted: (data) => {
      setDynoIdList(data.qset.dynosUnderQSet);
      if (isReview) {
        setSelectedDynoId(data.qset.dynosUnderQSet[0].id);
        setCurrentQSetDynoIndex(0 + 1);
        getSelectedDyno(data.qset.dynosUnderQSet[0].id, user.id);
        setDisablePrev(true);
        return true;
      }
      if (data.qset.qSetSubmissions.length === 0) {
        setSelectedDynoId(data.qset.dynosUnderQSet[0].id);
        setCurrentQSetDynoIndex(0 + 1);
        getSelectedDyno(data.qset.dynosUnderQSet[0].id, user.id);
        setLastAttemptedDynoId("");
        return true;
      }
      const qSetSubmissions = data.qset.qSetSubmissions[0];
      if (qSetSubmissions.isAllQuestionSubmitted) {
        setSelectedDynoId(data.qset.dynosUnderQSet[0].id);
        setCurrentQSetDynoIndex(0 + 1);
        getSelectedDyno(data.qset.dynosUnderQSet[0].id, user.id);
        setLastAttemptedDynoId("");
        return true;
      }
      const index = data.qset.dynosUnderQSet.findIndex(
        (dy) => dy.id === data.qset.qSetSubmissions[0].lastDynoAttempted.id,
      );
      setCurrentQSetDynoIndex(Number(index) + 2); //The index is for the lastDyno, so currentDyno is index+1 as index starts with 0 hence index+1+1
      setSelectedDynoId(data.qset.dynosUnderQSet[index + 1].id);
      getSelectedDyno(data.qset.dynosUnderQSet[index + 1].id, user.id);
      setLastAttemptedDynoId(data.qset.qSetSubmissions[0].lastDynoAttempted.id);
    },
    fetchPolicy: "cache-and-network",
  });

  const [sendFeedbackForBadResponse] = useMutation(SENDFEEDBACKFORBADRESPONSE, {
    onCompleted: () => {
      setFeedback("")
      setFeedbackResponse({sent: true, success: true, message: "Submitted Feedback!"})
      const chatCopy = [...chat]
      chatCopy[chat.length - 1] = {...chatCopy[chat.length - 1], like: false, dislike: true}
      setChat(chatCopy)
      localStorage.setItem("chat", JSON.stringify(chatCopy))
      setTimeout(() => {
        setOpenModal(false)
        setFeedbackResponse({})
      }, 2000)
    },
    onError: () => {
      setFeedback("")
      setFeedbackResponse({sent: true, success: false, message: "Failed To Submit Feedback!"})
      setTimeout(() => {
        setOpenModal(false)
        setFeedbackResponse({})
      }, 2000)
    }
  })

  const submitFeedback = () => {
    const ques = document?.querySelector("questionrow:nth-child(1) questioncell:nth-child(2) cellcontent")?.innerHTML
    let parser = new DOMParser();
    let doc = parser.parseFromString(ques, 'text/html');
    removeAttributes(doc.body);
    let updatedQuestionHTML = doc.body.innerHTML;
    const data = {
      sender: process.env.REACT_APP_SENDER_MAIL,
      receivers: [process.env.REACT_APP_RECEIVER_MAIL],
      user: user.id,
      name: updatedQuestionHTML,
      message: feedback,
      response: JSON.stringify(chat),
      url: window.location.href
    }
    sendFeedbackForBadResponse({
      variables: {
        data: data
      }
    })
  }

  function extractFollowUpQuestions(htmlString) {
    // Create a DOM parser
    const parser = new DOMParser();
    
    // Parse the HTML string
    const doc = parser.parseFromString(htmlString, 'text/html');
    
    // Find the last ordered list in the document (assuming it's the follow-up questions)
    const followUpList = doc.querySelectorAll('ol');
  
    // Get the last ordered list (which we assume contains the follow-up questions)
    const lastList = followUpList[followUpList.length - 1];
    
    // Convert nodeList to array
    let  followUpQuestions = Array.from(lastList.querySelectorAll('li')) ? Array.from(lastList.querySelectorAll('li')) : [...lastList.querySelectorAll('li')]
    followUpQuestions = followUpQuestions.map(item => item.textContent.trim());
    
    if (lastList) {
      lastList.parentNode.removeChild(lastList);
    }
    const followUpPara = Array.from(doc.querySelectorAll('p'))?.find(p => 
      p.textContent.toLowerCase().includes("follow-up")
    );

    if (followUpPara) {
      followUpPara.parentNode.removeChild(followUpPara);
    }

    const newHtmlString = new XMLSerializer().serializeToString(doc);
    
    return {
      followUpQuestions: followUpQuestions,
      newHtmlString: newHtmlString
    };
  }
  
  const [getAIChat] = useLazyQuery(AICHAT, {
    onCompleted: (data) => {
      setChatLoading(false)
      setResponse(data.ninjaLearnerChat)
      const chatCopy = [...chat]
      const number = chat.length - 1
      chatCopy[number] = {...chatCopy[number], question: chatCopy[number].question, response: extractFollowUpQuestions(data.ninjaLearnerChat).newHtmlString, like: false, dislike: false}
      setChat(chatCopy)
      localStorage.setItem("chat", JSON.stringify(chatCopy))
      localStorage.removeItem("followupQuestion")
      setFollowUpQuestions(extractFollowUpQuestions(data.ninjaLearnerChat).followUpQuestions)
      localStorage.setItem("followupQuestion", JSON.stringify(extractFollowUpQuestions(data.ninjaLearnerChat).followUpQuestions))
      if(chatCopy.filter(c => !c.regenerate).length >= 4) {
        localStorage.removeItem("followupQuestion")
        setFollowUpQuestions("Reached Maximum Limit")
        localStorage.setItem("followupQuestion", "Reached Maximum Limit")
      }
    },
    onError: () => {
      setChatLoading(false)
      setChatLoading(false)
    },
    fetchPolicy: "network-only",
  });

  function removeAttributes(element, attributesToRemove = ['style', 'class', 'data-fontsize']) {
    // Remove the specified attributes from the current element
    attributesToRemove.forEach(attr => {
        if (element.hasAttribute(attr)) {
            element.removeAttribute(attr);
        }
    });

    // Recursively call the function for each child element
    Array.from(element.children).forEach(child => removeAttributes(child, attributesToRemove));
  }

  const askAI = (message, isRegenerate=false) => {
    const num = chat.length
    const chatCopy = [...chat]
    if(isRegenerate) {
      chatCopy[num] = {question: message, response: !!chatCopy[num]?.response ? chatCopy[num].response : "", regenerate: true}
    } else {
      chatCopy[num] = {question: message, response: !!chatCopy[num]?.response ? chatCopy[num].response : ""}
    }
    setChat(chatCopy)
    localStorage.setItem("chat", JSON.stringify(chatCopy))
    const ques = document?.querySelector("questionrow:nth-child(1) questioncell:nth-child(1) cellcontent").innerHTML
    let parser = new DOMParser();
    let doc = parser.parseFromString(ques, 'text/html');
    removeAttributes(doc.body);
    let updatedQuestionHTML = doc.body.innerHTML;
    setChatLoading(true)
    const img = doc.body.querySelector("span[data-type=image]")?.getAttribute("fieldid") ? doc.body.querySelector("span[data-type=image]")?.getAttribute("fieldid") : document?.querySelector("questionrow:nth-child(1) questioncell:nth-child(1) cellcontent").querySelector("span[data-type=image]")?.getAttribute("fieldid")
    if(img) {
      const questionImage = JSON.parse(selectedDyno.myFields.filter(field => field.info.name.includes(img))[0].info.v_attrs).src
      getAIChat({
        variables: {
          where: {
            message: updatedQuestionHTML,
            image: questionImage,
            isFollowUp: false,
            hasHiddenObjects: hasHiddenObjects
          }
        }
      })
    } else {
      getAIChat({
        variables: {
          where: {
            message: updatedQuestionHTML,
            isFollowUp: false,
            hasHiddenObjects: hasHiddenObjects
          }
        }
      })
    }
  }

  const askFollowUPQuestion = (followUpMessage, isRegenerate=false) => {
    const num = chat.length
    const chatCopy = [...chat]
    if(isRegenerate) {
      chatCopy[num] = {question: "Regenerate the solution", response: !!chatCopy[num]?.response ? chatCopy[num].response : "", regenerate: true}
    } else {
      chatCopy[num] = {question: followUpMessage, response: !!chatCopy[num]?.response ? chatCopy[num].response : ""}
    }
    setChat(chatCopy)
    localStorage.setItem("chat", JSON.stringify(chatCopy))
    const ques = document?.querySelector("questionrow:nth-child(1) questioncell:nth-child(1) cellcontent")?.innerHTML
    let parser = new DOMParser();
    let doc = parser.parseFromString(ques, 'text/html');
    removeAttributes(doc.body);
    let updatedQuestionHTML = doc.body.innerHTML;
    setChatLoading(true)
    const img = doc.body.querySelector("span[data-type=image]")?.getAttribute("fieldid") ? doc.body.querySelector("span[data-type=image]")?.getAttribute("fieldid") : document?.querySelector("questionrow:nth-child(1) questioncell:nth-child(1) cellcontent").querySelector("span[data-type=image]")?.getAttribute("fieldid")
    if(img) {
      const questionImage = JSON.parse(selectedDyno.myFields.filter(field => field.info.name.includes(img))[0].info.v_attrs).src
      getAIChat({
        variables: {
          where: {
            message: updatedQuestionHTML,
            image: questionImage,
            response: response,
            followUpMessage: followUpMessage,
            isFollowUp: true,
          }
        }
      })
    } else {
      getAIChat({
        variables: {
          where: {
            message: updatedQuestionHTML,
            response: response,
            followUpMessage: followUpMessage,
            isFollowUp: true,
          }
        }
      })
    }
  }

  const regenerate = (message) => {
    if(message === "Explain the solution step by step") {
      askAI("Regenerate the solution", true)
    } else {
      askFollowUPQuestion(message, true)
    }
  }

  const toggleAIChat = () => {
    setToggleChat(!toggleChat)
  }

  const askFirstQuestion = () => {
    askAI("Explain the solution step by step", 0)
    if(!hideFirstQuestionBtn) setToggleChat(false)
    setHideFirstQuestionBtn(true)
  }

  const onReview = (like, dislike, index) => {
    if(!dislike) {
      const chatCopy = [...chat]
      chatCopy[index] = {...chatCopy[index], like, dislike}
      setChat(chatCopy)
      localStorage.setItem("chat", JSON.stringify(chatCopy))
    } else {
      setOpenModal(true)
    }
  }

  useEffect(() => {
    getRequestStores();
    loadingCallback(true, "");
    const c = localStorage.getItem("chat")
    const f = localStorage.getItem("followupQuestion")
    
    if(c) setChat(JSON.parse(c))
    if(c && c.length > 0) setHideFirstQuestionBtn(true)
    if(f) setFollowUpQuestions(f === "Reached Maximum Limit" ? f : JSON.parse(f))
    
  }, [getRequestStores, loadingCallback]);

  useEffect(() => {
    const url = new URL(window.location);
    const qSet = url.searchParams.get("qsetid");
    setQSetId(qSet);
    if(user.id) {
      getQSet({
        variables: {
          where: {
            id: qSet,
          },
        },
      });
    }
  }, [getQSet, user.id]);

  useEffect(()=>{
    if(typeof window?.MathJax !== "undefined"){
      window.MathJax.typeset()
    }
  },[])

  const getSelectedDyno = (dynoId, userId) => {
    getDyno({
      variables: {
        where: {
          id: dynoId,
        },
        ...(isReview ? {
          submission_where: {
            AND: [
              {
                submitter: {
                  id: { equals: userId },
                },
                dyno: {
                  id: { equals: dynoId },
                },
              },
            ],
          },
        }: {})
      },
    });
  };

  const getDynoData = (gqlDyno) => {
    let myDyno = {
      _id: gqlDyno.id,
      owner_id: gqlDyno.owner && gqlDyno.owner.id,
      owner_email: gqlDyno.owner && gqlDyno.owner.email,
      function_ids:
        gqlDyno.functions &&
        gqlDyno.functions.map(function (_ref9) {
          var id = _ref9.id;
          return id;
        }),
      updatedAt: gqlDyno.updatedAt,
      info: {
        name: gqlDyno.dynoTitle,
        description: gqlDyno.description,
        html_code: gqlDyno.dynoContent,
        category: gqlDyno.category && gqlDyno.category.id,
        dyno_type: gqlDyno.dynoType && gqlDyno.dynoType.name,
        dyno_type_id: gqlDyno.dynoType && gqlDyno.dynoType.id,
        is_public:
          gqlDyno.accessType === "ANYONEWITHLINK" ||
          gqlDyno.accessType === "PUBLIC",
        is_draft: gqlDyno.status && gqlDyno.status.statusType === "Draft",
        allow_rating: gqlDyno.allowRating,
        allow_comments: gqlDyno.allowComments,
        tags:
          gqlDyno.tags &&
          gqlDyno.tags.map(function (_ref10) {
            var id = _ref10.id;
            return id;
          }),
        locale_date: gqlDyno.localeDate ? gqlDyno.localeDate.name : null,
        locale: gqlDyno.locale ? gqlDyno.locale.name : null,
        maxPoints: gqlDyno.maxPoints,
        instructions: gqlDyno.instructions,
        dueDate: gqlDyno.dueDate,
        isTimedDyno: gqlDyno.isTimedDyno,
        timedDynoDuration: gqlDyno.timedDynoDuration,
        viewCount: gqlDyno.viewCount,
      },
      parentDyno: gqlDyno.parentDyno,
      isStudentDyno: false,
      rubric: gqlDyno.rubric,
      submission_id:
        gqlDyno.submissions?.length > 0 ? gqlDyno.submissions[0].id : "",
      submission_variables: gqlDyno.submissionsVariables,
      submission_variables_db: JSON.parse(
        JSON.stringify(gqlDyno.submissionsVariables),
      ),
      lmsId: gqlDyno.lmsId,
      otherInfo: gqlDyno.otherInfo,
    };
    let myFields = [];

    if (myDyno) {
      if (gqlDyno.variables.length) {
        myFields = gqlDyno.variables.map(function (variable) {
          return {
            _id: variable.id,
            info: {
              name: variable.fieldName,
              description: variable.description,
              v_type:
                variable.variableType && variable.variableType.variableType,
              v_default: {
                value: pathname === "/qreview/" ? getDefaultValue(gqlDyno.submissions[0], variable) : variable.defaultValue,
              },
              v_attrs: variable.variableAttributes
                ? JSON.stringify(variable.variableAttributes)
                : "{}",
              rules: variable.variableRules,
            },
            possibleAnswers: variable.possibleAnswers,
          };
        });
      }
    }
    let myGrids = [];
    if (myDyno) {
      if (gqlDyno.grids.length) {
        myGrids = gqlDyno.grids;
      }
    }
    let mySubmission = isReview ? gqlDyno.submissions[0] : {};
    return { myDyno, myFields, myGrids, mySubmission };
  };

  const submissionCallback = (submission) => {
    setLastAttemptedDynoId(submission.data.upsertQSetDynoSubmission.submission.dyno.id);
    const dynoIndex = dynoIdList.findIndex((lst) => lst.id === selectedDynoId);
    if (dynoIndex + 1 === dynoIdList.length) {
      if(submission.data.upsertQSetDynoSubmission?.newBadgeUnlocked !== null) {
        const data = {
          badgeName: submission.data.upsertQSetDynoSubmission?.newBadgeUnlocked?.badgeName,
          badgeImage: submission.data.upsertQSetDynoSubmission?.newBadgeUnlocked?.badgeImage,
        }
        localStorage.setItem('badge', JSON.stringify(data))
      }
      setTimeout(() => {
        history.push(
          `/qreport?appid=${appid}&userid=${userid}&qreportid=${qsetid}`,
        );
      }, 1000)
    } else {
      setDynoLoading(true);
      setSelectedDynoId(dynoIdList[dynoIndex + 1].id);
      getSelectedDyno(dynoIdList[dynoIndex + 1].id, user.id);
    }
  };

  const onChangeDyno = (isNext) => {
    localStorage.clear()
    setFollowUpQuestions([])
    setHideFirstQuestionBtn(false)
    setChat([])
    setToggleChat(true)
    const dynoIndex = dynoIdList.findIndex((lst) => lst.id === selectedDynoId);
    if (isNext) {
      const fromPathN = sessionStorage.getItem("fromPathN")
      sessionStorage.setItem("fromPathN", Number(fromPathN) + 1)
      if (dynoIndex + 1 === dynoIdList.length) {
        setDisableNext(true);
      } else {
        setDisableNext(false);
        setDynoLoading(true);
        setSelectedDynoId(dynoIdList[dynoIndex + 1].id);
        getSelectedDyno(dynoIdList[dynoIndex + 1].id, user.id);
      }
    } else {
      const fromPathN = sessionStorage.getItem("fromPathN")
      sessionStorage.setItem("fromPathN", Number(fromPathN) - 1)
      if (dynoIndex - 1 === -1) {
        setDisablePrev(true);
        sessionStorage.removeItem("fromPathN")
      } else {
        setDisablePrev(false);
        setDynoLoading(true);
        setSelectedDynoId(dynoIdList[dynoIndex - 1].id);
        getSelectedDyno(dynoIdList[dynoIndex - 1].id, user.id);
      }
    }
  };

  const navigateToHome = () => {
    history.replace(`/?appid=${appid}&userid=${userid}`)
  }

  return {
    selectedDyno,
    qSetId,
    requestStores,
    lastAttemptedDynoId,
    submissionCallback,
    dynoLoading,
    currentQSetDynoIndex,
    onChangeDyno,
    navigateToHome,
    disableNext,
    disablePrev,
    isLastDynoOfQSet,
    askAI,
    chatLoading,
    toggleAIChat,
    toggleChat,
    followUpQuestions,
    chat,
    askFollowUPQuestion,
    askFirstQuestion,
    hideFirstQuestionBtn,
    onReview,
    regenerate,
    openModal,
    closeModal,
    setFeedback,
    feedback,
    submitFeedback,
    feedbackResponse
  };
};
