import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { USERQAPPREPORT } from "../../graphql/queries";

export const ReportPage = ({ user, userRole, loadingCallback }) => {
  const [userQAppReport, setUserQAppReport] = useState([]);

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const appid = queryParams.get("appid");
  const userid = queryParams.get("userid");

  const [getUserQAppReport] = useLazyQuery(USERQAPPREPORT, {
    onCompleted: (data) => {
      setUserQAppReport(data.userQAppReport);
      loadingCallback(false, "");
    },
    onError: () => {
      loadingCallback(false, "");
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    loadingCallback(true, "loading app report...");
    if(userid && appid) {
      getUserQAppReport({
        variables: {
          where: {
            userId: userid,
            qAppId: appid,
          },
        },
      });
    }
  }, [appid, userid, getUserQAppReport, loadingCallback]);

  return (
    <Box padding={{ base: "20px 10px", md: "20px 20px" }} className="report-container">
      <Box className="report-card">
        <Heading
          as="h2"
          sx={{
            textAlign: "center",
            textTransform: "uppercase",
            fontSize: "1.5rem",
            margin: {
              sm: "10px 0",
              md: "20px 0",
            },
          }}
        >
          Report
        </Heading>
        <UnorderedList
          sx={{
            maxWidth: "500px",
            width: "100%",
            margin: "20px auto 0",
            lineHeight: "2",
            listStyle: "none",
          }}
        >
          {React.Children.toArray(
            userQAppReport.map((report) => (
              <ListItem
                sx={{
                  // display: 'flex',
                  // alignItems: 'center',
                  // justifyContent: 'space-between',
                  margin: "0 0 10px",
                }}
              >
                <Heading
                  as="h2"
                  sx={{ fontSize: "24px", margin: "0 0 20px" }}
                ><Box as="span" style={{fontSize: "20px", fontWeight:"600"}}>Skill: </Box>{report?.level}</Heading>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 0 25px",
                  }}
                >
                  <Text sx={{ display: "flex", fontWeight: "bold" }}>
                    <Image
                      src="./images/document.png"
                      alt="badge locked"
                      sx={{
                        width: "32px",
                        height: "32px",
                        margin: "0 10px 0 0",
                      }}
                    />{" "}
                    <Box as="span" style={{fontSize: "18px", fontWeight:"500", textAlign:"left"}}>Number of questions attempted</Box>
                  </Text>
                  <Text fontSize={{sm:"22px", md: "24px"}} fontWeight="600">{report?.numberOfQuestionsAttempted}</Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 0 25px",
                  }}
                >
                  <Text sx={{ display: "flex", fontWeight: "bold" }}>
                    <Image
                      src="./images/correct.png"
                      alt="badge locked"
                      sx={{
                        width: "32px",
                        height: "32px",
                        margin: "0 10px 0 0",
                      }}
                    />{" "}
                    <Box as="span" style={{fontSize: "18px", fontWeight:"500", textAlign:"left"}}>Correctly answered</Box>
                  </Text>
                  <Text fontSize={{sm:"22px", md: "24px"}} fontWeight="600">{report?.correctlyAnswered}</Text>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    margin: "0 0 25px",
                  }}
                >
                  <Text sx={{ display: "flex", fontWeight: "bold" }}>
                    <Image
                      src="./images/time.png"
                      alt="badge locked"
                      sx={{
                        width: "36px",
                        height: "36px",
                        margin: "0 10px 0 0",
                      }}
                    />{" "}
                    <Box as="span" style={{fontSize: "18px", fontWeight:"500", textAlign:"left"}}>Average time taken per question</Box>
                  </Text>
                  <Text fontSize={{sm:"22px", md: "24px"}} fontWeight="600">{report?.avgTimeTakenPerQuestion}</Text>
                </Box>
              </ListItem>
            )),
          )}
        </UnorderedList>
        {userQAppReport.length === 0 && (
          <Text sx={{ textAlign: "center", fontSize: "1.2rem" }}>
            No reports to show yet!
          </Text>
        )}
      </Box>
    </Box>
  );
};
