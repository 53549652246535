import React from "react";
import { Box, Flex, Button, Text, Image } from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Header } from "../Header";
import { b2cAppUrl } from "../../config";

export const PageLayout = ({
  children,
  loading,
  user,
  appName,
  bgColor = "#f7fafb",
}) => {

  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const appid = queryParams.get("appid");
  const userid = queryParams.get("userid");
  const history = useHistory();
  return (
    <Box
      position={loading ? "fixed" : "unset"}
      width="100%"
      height={loading ? "100vh" : "auto"}
    >
      <Header isAuthenticated={true} user={user} showMenuButton={false} />
      <Box as="main" className={`${(pathname === "/report" || pathname === "/qreport") ? "background-image" : ""}`} sx={{minHeight: "calc(100vh - 64px)", background: "#f7fafb"}}>
        {pathname !== "/qreview/" &&
          <Flex
            sx={{
              alignItems: "center",
              position: "relative",
              padding: "20px",
              "&:after": {
                content: "''",
                position: "absolute",
                width: "2px",
                height: "100%",
                top: "0",
                right: "0",
                background: "#eee",
              },
            }}
          >
            <Button
              leftIcon={<ArrowBackIcon />}
              onClick={() => {
                if (pathname === "/") {
                  window.location.href = b2cAppUrl
                  return true
                  // history.replace(b2cAppUrl)
                }
                if (pathname.includes("report")) {
                  history.replace(`/?appid=${appid}&userid=${userid}`)
                  return true
                }
                const fromPath = sessionStorage.getItem("fromPath")
                if (!!fromPath) {
                  sessionStorage.removeItem("fromPath")
                  history.replace(fromPath)
                } else {
                  history.goBack()
                }
              }}
              variant={"outline"}
              colorScheme="brand"
            >
              <Text
                sx={{
                  margin: pathname === "/" ? "0 20px 0 0" : "0 20px 0 10px",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {pathname === "/" ? 'Exit App' : 'Back'}
              </Text>
            </Button>

          </Flex>
        }
        {children}
      </Box>
    </Box>
  );
};
