import React, { useEffect, useState } from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom/";
import { useLazyQuery } from "@apollo/client";
import { format } from "date-fns";
import { QSETS } from "../../graphql/queries";
import { PaginatedDataTable } from "../../components/PaginatedDataTable";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  getAvgTimeTakenPerQuestion,
  secondsToMinutesAndSeconds,
} from "../../utils/helper";

export const PreviousTestsPage = ({ user, userRole, loadingCallback }) => {
  const [qsets, setQsets] = useState([]);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const appid = queryParams.get("appid");
  const userid = queryParams.get("userid");
  const history = useHistory();

  const [getQsets] = useLazyQuery(QSETS, {
    onCompleted: (data) => {
      setQsets(
        data?.qsets.map((qset) => {
          const submission =
            qset?.qSetSubmissions.length > 0 ? qset?.qSetSubmissions[0] : {};
          const totalQuestions = submission.id
            ? submission?.qset?.dynosUnderQSet.length
            : null;
          return {
            ...qset,
            name: qset?.qSetName,
            submittedAt: submission?.id && submission?.isAllQuestionSubmitted
              ? format(new Date(submission?.updatedAt), "MM/dd/yyyy")
              : null,
            score: submission?.id && submission?.isAllQuestionSubmitted
              ? `${submission?.qsetScore}/${totalQuestions}`
              : null,
            totalTimeTaken: submission?.id && submission?.isAllQuestionSubmitted
              ? secondsToMinutesAndSeconds(submission?.qsetTimeTaken)
              : null,
            avgTimeTaken: submission?.id && submission?.isAllQuestionSubmitted
              ? getAvgTimeTakenPerQuestion(
                  Number(submission?.qsetTimeTaken),
                  Number(totalQuestions),
                )
              : null,
            status: submission?.id
              ? submission?.isAllQuestionSubmitted
                ? "View Responses"
                : "Continue"
              : "Try Now",
          };
        }),
      );
      loadingCallback(false, "");
    },
    onError: () => {
      loadingCallback(false, "");
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    loadingCallback(true, "loading previous tests...");
    getQsets({
      variables: {
        where: {
          AND: [
            {
              QApp: {
                id: { equals: appid },
              },
            },
            {
              userId: {
                equals: userid,
              },
            },
          ],
        },
      },
    });
  }, [appid, userid, getQsets, loadingCallback]);

  const practiceQset = (value) => {
    sessionStorage.setItem("fromPath", `/previoustests?appid=${appid}&userid=${userid}`)
    history.push(value.status === "View Responses" ? `/qreview/?appid=${appid}&userid=${userid}&qsetid=${value.id}` : `/qviewer/?appid=${appid}&userid=${userid}&qsetid=${value.id}`);
  };

  const qsetColumns = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ cell: { value } }) => <Text>{value}</Text>,
    },
    {
      Header: "Date Of Test",
      accessor: "submittedAt",
      Cell: ({ cell: { value } }) => <Text>{value ? value : "-"}</Text>,
    },
    {
      Header: "Score",
      accessor: "score",
      Cell: ({ cell: { value } }) => <Text color="#29b473" fontWeight="700" fontSize= "18px" borderColor="rgba(131, 90, 238,0.12)">{value ? value : "-"}</Text>,
    },
    {
      Header: "Total Time Taken",
      accessor: "totalTimeTaken",
      Cell: ({ cell: { value } }) => <Text>{value ? value : "-"}</Text>,
    },
    {
      Header: "Avg Time Per Question",
      accessor: "avgTimeTaken",
      Cell: ({ cell: { value } }) => <Text>{value ? value : "-"}</Text>,
    },
    ...(userRole === "Student"
      ? [
          {
            Header: "Practice",
            Cell: ({ cell }) => {
              return (
                <Button
                  variant="ghost"
                  colorScheme={cell.row.original.status === "Continue" ? "yellow" : "brand"}
                  aria-label="practice"
                  onClick={() => practiceQset(cell.row.original)}
                  rightIcon={<ArrowForwardIcon />}
                  textTransform={cell.row.original.status === "Continue" ? "uppercase" : "capitalize"}
                >
                  {cell.row.original.status}
                </Button>
              );
            },
          },
        ]
      : []),
    ...(userRole === "Student"
      ? [
          {
            Header: "TryAgain",
            Cell: ({ cell }) => 
              cell.row.original.status === "View Responses" ? 
                <Button
                  variant="ghost"
                  colorScheme="brand"
                  aria-label="practice"
                  onClick={() => history.push(`/qviewer/?appid=${appid}&userid=${userid}&qsetid=${cell.row.original.id}`)}
                  rightIcon={<ArrowForwardIcon />}
                >
                  Try Again
                </Button> : <></>
            ,
          },
        ]
      : []),
    
  ];

  return (
    <Box padding={{ base: "20px 10px", md: "20px 20px" }}>
      <Heading
        as="h2"
        size="md"
        sx={{
          textAlign: "center",
          textTransform: "uppercase",
          margin: {
            sm: "10px 0",
            md: "20px 0",
          },
        }}
      >
        Study from previous tests
      </Heading>
      <PaginatedDataTable
        columns={qsetColumns}
        data={qsets}
        showNumberOfRecords={100}
      />
    </Box>
  );
};
