import {
  userpoolId,
  userpoolClientId,
  cognitoRegionName,
  userpoolIdIn,
  userpoolClientIdIn,
  cognitoRegionNameIn,
  appDomain,
  india
} from "../config";
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
export const awsconfig = {
  region: timeZone === india ? cognitoRegionNameIn : cognitoRegionName,
  userPoolId: timeZone === india ? userpoolIdIn : userpoolId,
  userPoolWebClientId: timeZone === india ? userpoolClientIdIn : userpoolClientId,
  cookieStorage: {
    domain: appDomain,
    path: "/",
    expires: 365,
    secure: true,
  },
};
