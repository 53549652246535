import { useEffect, useState, useRef } from "react";
import { Auth, Hub } from "aws-amplify";
import { useLazyQuery } from "@apollo/client";
import { makeCookieString } from "../utils/cookie";
import { USER } from "../graphql/queries";

export const useLogin = () => {
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [userNotExist, setUserNotExist] = useState(false);
  const currentUserRef = useRef({});
  const [user, setUser] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    userPicture: null,
  });
  const [userRole, setUserRole] = useState("");

  const [getUser] = useLazyQuery(USER, {
    onCompleted: (data) => {
      if (data.indParents.length > 0) {
        const pupil = data.indParents[0].pupil.find(
          (child) =>
            child.email === currentUserRef?.current?.email ||
            child.phone === currentUserRef?.current?.phone ||
            child.username === currentUserRef?.current?.username,
        );
        const parent = data.indParents[0].parent
          ? data.indParents[0].parent
          : {};
        const userRole = !!pupil && pupil.id ? "Student" : "Parent";
        setUserRole(userRole);
        setUser(
          userRole === "Parent"
            ? {
                ...parent,
                username: currentUserRef?.current?.username,
                userPicture: null,
              }
            : {
                ...pupil,
                username: currentUserRef?.current?.username,
                userPicture: null,
              },
        );
      } else {
        setUserNotExist(true);
      }
    },
  });

  Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case "signIn":
        setIsLoggedin(true);
        document.cookie = makeCookieString(
          "ninja_session",
          data.signInUserSession.idToken.jwtToken,
          6,
        );
        getAWSUser();
        break;
      case "signOut":
        setIsLoggedin(false);
        break;
      default:
      // do nothing
    }
  });

  const getAWSUser = async () => {
    try {
      const userInfo = await Auth.currentAuthenticatedUser();
      setUser({
        firstName: userInfo.attributes.given_name,
        lastName: userInfo.attributes.family_name,
        picture: null,
        username: userInfo.username,
        email: userInfo.attributes.email,
        phone: userInfo.attributes.phone_number,
      });
      currentUserRef.current = {
        firstName: userInfo.attributes.given_name,
        lastName: userInfo.attributes.family_name,
        username: userInfo.username,
        picture: null,
        email: userInfo.attributes.email,
        phone: userInfo.attributes.phone_number,
      };
      getUser({
        variables: {
          where: {
            OR: [
              {
                pupil: {
                  some: {
                    ...(userInfo.attributes.email
                      ? { email: { equals: userInfo.attributes.email } }
                      : {}),
                    ...(userInfo.attributes.phone_number
                      ? { phone: { equals: userInfo.attributes.phone_number } }
                      : {}),
                    ...(!userInfo.attributes.email &&
                    !userInfo.attributes.phone_number
                      ? { username: { equals: userInfo.username } }
                      : {}),
                  },
                },
              },
              {
                parent: {
                  ...(userInfo.attributes.email
                    ? { email: { equals: userInfo.attributes.email } }
                    : {}),
                  ...(userInfo.attributes.phone_number
                    ? { phone: { equals: userInfo.attributes.phone_number } }
                    : {}),
                  ...(!userInfo.attributes.email &&
                  !userInfo.attributes.phone_number
                    ? { username: { equals: userInfo.username } }
                    : {}),
                },
              },
            ],
          },
        },
      });
      const currentSession = await Auth.currentSession();
      userInfo.refreshSession(
        currentSession.refreshToken,
        async (err, session) => {
          const { idToken } = session;
          document.cookie = makeCookieString(
            "ninja_session",
            idToken.jwtToken,
            idToken.payload.exp,
            true,
          );
        },
      );
    } catch {
      setIsLoggedin(false);
    }
  };

  const getAccessJwtToken = async () => {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
    try {
      const session = await Auth.currentSession();
      const token = session.getIdToken().getJwtToken();
      const exp = session.getIdToken().payload.exp;
      if (token) {
        setIsLoggedin(true);
        document.cookie = makeCookieString("ninja_session", token, exp, true);
        getAWSUser();
      }
    } catch {
      // user will login
    }
  };

  useEffect(() => {
    if (!isLoggedin) {
      getAccessJwtToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedin]);

  return [isLoggedin, user, userRole, userNotExist];
};
