import React from 'react';
import './Confetti.scss'; // Ensure you create this CSS file to handle the styles for your confetti

export const Confetti = () => {
  const confettiItems = [
    { speed: 10, bg: 'yellow', className: 'square' },
    { speed: 18, bg: 'white', className: 'pentagram' },
    { speed: 29, bg: 'green', className: 'rectangle' },
    { speed: 17, bg: 'blue', className: 'hexagram' },
    { speed: 33, bg: 'red', className: 'pentagram' },
    { speed: 26, bg: 'yellow', className: 'dodecagram' },
    { speed: 24, bg: 'pink', className: 'wavy-line' },
    { speed: 5, bg: 'blue', className: 'wavy-line' },
    { speed: 40, bg: 'white', className: 'square' },
    { speed: 17, bg: 'green', className: 'rectangle' },
    { speed: 25, bg: 'white', className: 'square' },
    { speed: 18, bg: 'green', className: 'rectangle' },
    { speed: 15, bg: 'yellow', className: 'wavy-line' },
    { speed: 32, bg: 'yellow', className: 'pentagram' },
    { speed: 25, bg: 'white', className: 'square' },
    { speed: 18, bg: 'green', className: 'rectangle' },
    { speed: 37, bg: 'yellow', className: 'dodecagram' },
    { speed: 23, bg: 'pink', className: 'wavy-line' },
    { speed: 37, bg: 'red', className: 'dodecagram' },
    { speed: 37, bg: 'pink', className: 'wavy-line' },
    { speed: 36, bg: 'white', className: 'hexagram' },
    { speed: 32, bg: 'green', className: 'wavy-line' },
    { speed: 32, bg: 'yellow', className: 'pentagram' },
    { speed: 29, bg: 'white', className: 'square' },
    { speed: 18, bg: 'green', className: 'rectangle' },
    { speed: 37, bg: 'red', className: 'dodecagram' },
    { speed: 23, bg: 'pink', className: 'wavy-line' },
    { speed: 30, bg: 'pink', className: 'rectangle' },
    { speed: 30, bg: 'red', className: 'square' },
    { speed: 18, bg: 'red', className: 'pentagram' },
    { speed: 19, bg: 'green', className: 'rectangle' },
    { speed: 16, bg: 'blue', className: 'hexagram' },
    { speed: 23, bg: 'red', className: 'pentagram' },
    { speed: 34, bg: 'yellow', className: 'dodecagram' },
    { speed: 39, bg: 'pink', className: 'wavy-line' },
    { speed: 40, bg: 'purple', className: 'square' },
    { speed: 21, bg: 'green', className: 'rectangle' },
    { speed: 14, bg: 'white', className: 'square' },
    { speed: 38, bg: 'green', className: 'rectangle' },
    { speed: 19, bg: 'red', className: 'dodecagram' },
    { speed: 29, bg: 'pink', className: 'wavy-line' },
    { speed: 21, bg: 'white', className: 'hexagram' },
    { speed: 17, bg: 'purple', className: 'wavy-line' },
    { speed: 32, bg: 'yellow', className: 'pentagram' },
    { speed: 23, bg: 'white', className: 'square' },
    { speed: 18, bg: 'green', className: 'rectangle' },
    { speed: 37, bg: 'red', className: 'dodecagram' },
    { speed: 48, bg: 'pink', className: 'wavy-line' },
    { speed: 38, bg: 'pink', className: 'rectangle' },
    { speed: 13, bg: 'red', className: 'pentagram' },
    { speed: 49, bg: 'yellow', className: 'dodecagram' },
    { speed: 19, bg: 'cyan', className: 'wavy-line' },
    { speed: 15, bg: 'steelblue', className: 'square' },
    { speed: 10, bg: 'yellow', className: 'square' },
    { speed: 18, bg: 'white', className: 'pentagram' },
    { speed: 29, bg: 'green', className: 'rectangle' },
    { speed: 17, bg: 'blue', className: 'hexagram' },
    { speed: 33, bg: 'red', className: 'pentagram' },
    { speed: 26, bg: 'yellow', className: 'dodecagram' },
    { speed: 24, bg: 'pink', className: 'wavy-line' },
    { speed: 5, bg: 'white', className: 'wavy-line' },
    { speed: 40, bg: 'purple', className: 'square' },
    { speed: 17, bg: 'green', className: 'rectangle' },
    { speed: 25, bg: 'white', className: 'square' },
    { speed: 18, bg: 'green', className: 'rectangle' },
    { speed: 15, bg: 'cyan', className: 'wavy-line' },
    { speed: 32, bg: 'yellow', className: 'pentagram' },
    { speed: 45, bg: 'white', className: 'square' },
    { speed: 18, bg: 'green', className: 'rectangle' },
    { speed: 37, bg: 'red', className: 'dodecagram' },
    { speed: 23, bg: 'pink', className: 'wavy-line' },
    { speed: 37, bg: 'red', className: 'dodecagram' },
    { speed: 37, bg: 'pink', className: 'wavy-line' },
    { speed: 26, bg: 'white', className: 'hexagram' },
    { speed: 32, bg: 'cyan', className: 'wavy-line' },
    { speed: 32, bg: 'yellow', className: 'pentagram' },
    { speed: 45, bg: 'white', className: 'square' },
    { speed: 18, bg: 'green', className: 'rectangle' },
    { speed: 37, bg: 'red', className: 'dodecagram' },
    { speed: 23, bg: 'pink', className: 'wavy-line' },
    { speed: 50, bg: 'pink', className: 'rectangle' },
    { speed: 30, bg: 'red', className: 'square' },
    { speed: 18, bg: 'red', className: 'pentagram' },
    { speed: 19, bg: 'green', className: 'rectangle' },
    { speed: 16, bg: 'blue', className: 'hexagram' },
    { speed: 23, bg: 'red', className: 'pentagram' },
    { speed: 33, bg: 'yellow', className: 'dodecagram' },
    { speed: 39, bg: 'white', className: 'wavy-line' },
    { speed: 40, bg: 'orange', className: 'square' },
    { speed: 21, bg: 'green', className: 'rectangle' },
    { speed: 14, bg: 'white', className: 'square' },
    { speed: 38, bg: 'green', className: 'rectangle' },
    { speed: 19, bg: 'red', className: 'dodecagram' },
    { speed: 29, bg: 'pink', className: 'wavy-line' },
    { speed: 34, bg: 'white', className: 'hexagram' },
    { speed: 17, bg: 'indigo', className: 'wavy-line' },
    { speed: 32, bg: 'yellow', className: 'pentagram' },
    { speed: 23, bg: 'white', className: 'square' },
    { speed: 18, bg: 'green', className: 'rectangle' },
    { speed: 37, bg: 'red', className: 'dodecagram' },
    { speed: 48, bg: 'pink', className: 'wavy-line' },
    { speed: 38, bg: 'pink', className: 'rectangle' },
    { speed: 13, bg: 'red', className: 'pentagram' },
    { speed: 49, bg: 'yellow', className: 'dodecagram' },
    { speed: 19, bg: 'purple', className: 'wavy-line' },
    { speed: 15, bg: 'cyan', className: 'square' },
  ];

  return (
    <div className="confetti-container">
      <div className="confetti">
        {confettiItems.map((item, index) => (
          <i
            key={index}
            style={{ '--speed': item.speed, '--bg': item.bg }}
            className={item.className}
          ></i>
        ))}
      </div>
    </div>
  );
};

export default Confetti;
