import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useToast, useRadioGroup } from "@chakra-ui/react";
import { format } from "date-fns";
import { QAPP, GETQAPPLEVELSTATUS } from "../../graphql/queries";
import { GENERATEQSET } from "../../graphql/mutations";
import {
  getAvgTimeTakenPerQuestion,
  secondsToMinutesAndSeconds,
} from "../../utils/helper";

export const useHome = ({ user, loadingCallback }) => {
  const [myApp, setMyApp] = useState({});
  const [qsets, setQsets] = useState([]);
  const [myBadges, setMyBadges] = useState([]);
  const [levels, setLevels] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState("");
  const [currentQSet, setCurrentQSet] = useState({});
  const [qAppLevelStatus, setQAppLevelStatus] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { search } = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(search);
  const appid = queryParams.get("appid");
  const userid = queryParams.get("userid");

  const toast = useToast();

  const [getMyApp] = useLazyQuery(QAPP, {
    onCompleted: (data) => {
      setMyApp(data?.qapp);
      setCurrentQSet(data?.qsets.reduce((pv, cv) => {
        if (cv.qSetSubmissions.some(submission => !submission.isAllQuestionSubmitted) || cv.qSetSubmissions.length === 0) {
            pv = cv;
        }
        return pv;
      }, {}));
      setQsets(
        data?.qapp?.qsets.map((qset) => {
          const submission =
            qset?.qSetSubmissions.length > 0 ? qset?.qSetSubmissions[0] : {};
          const totalQuestions = submission.id
            ? submission?.qset?.dynosUnderQSet.length
            : null;
          return {
            ...qset,
            name: qset?.qSetName,
            submittedAt: submission?.id && submission?.isAllQuestionSubmitted
              ? format(new Date(submission?.updatedAt), "MM/dd/yyyy")
              : null,
            score: submission?.id && submission?.isAllQuestionSubmitted
              ? `${submission?.qsetScore}/${totalQuestions}`
              : null,
            totalTimeTaken: submission?.id && submission?.isAllQuestionSubmitted
              ? secondsToMinutesAndSeconds(submission?.qsetTimeTaken)
              : null,
            avgTimeTaken: submission?.id && submission?.isAllQuestionSubmitted
              ? getAvgTimeTakenPerQuestion(
                Number(submission?.qsetTimeTaken),
                Number(totalQuestions),
              )
              : null,
            status: submission?.id
              ? submission?.isAllQuestionSubmitted
                ? "View Responses"
                : "Continue"
              : "Try Now",
          };
        }),
      );
      const userBadges = data?.qapp?.myBadges;
      setMyBadges(
        data?.qapp?.app?.bundleAppProp?.badges?.reduce(
          (previousValue, currentValue) => {
            const index = userBadges.findIndex(
              (ub) => ub.id === currentValue.id,
            );
            previousValue.push({
              ...currentValue,
              isLocked: index === -1,
            });
            return previousValue;
          },
          [],
        ),
      );
      setLevels(data?.qapp?.app?.products.map((p,index) => ({id: p.id, name: p.productName, criteria: data?.qapp?.app?.bundleAppProp?.preReqs[index]})));
      setSelectedLevel(data?.qapp?.app?.products.length > 0
        ? data?.qapp?.app?.products[0].productName
        : "")
      setValue(data?.qapp?.app?.products.length > 0
        ? data?.qapp?.app?.products[0].productName
        : "")
      sessionStorage.setItem("appName", data?.qapp?.app?.bundleName);
      loadingCallback(false, "");
      setIsLoading(false);
    },
    onError: () => {
      loadingCallback(false, "");
    },
    fetchPolicy: "network-only"
  });

  const [getQAppLevelStatus] = useLazyQuery(GETQAPPLEVELSTATUS, {
    onCompleted: (data) => {
      setQAppLevelStatus(data.getQappLevelStatus)
    },
    onError: () => {
      loadingCallback(false, "");
    },
    fetchPolicy: "network-only"
  })

  const [generateQSet] = useMutation(GENERATEQSET, {
    onCompleted: (data) => {
      loadingCallback(false, "");
      setMyApp({
        ...myApp,
        qsets: [...myApp.qsets, data.generateQSet],
      });
      setCurrentQSet(data.generateQSet);
      toast({
        position: "top",
        title: "Generated question set.",
        description: "We've generated question set for you.",
        status: "success",
        duration: 4000,
      });
      history.push(
        `/qviewer/?appid=${appid}&userid=${userid}&qsetid=${data.generateQSet.id}`,
      );
    },
    onError: () => {
      loadingCallback(false, "");
      toast({
        position: "top",
        title: "Something went wrong please try again after sometime.",
        status: "error",
        duration: 4000,
      });
    },
  });

  useEffect(() => {
    if (user.id && appid) {
      loadingCallback(true, "Loading app...");
      getQAppLevelStatus({variables: {
        where: {
          id: appid,
          userId: user?.id
        }
      }})
      getMyApp({
        variables: {
          where: {
            id: appid,
          },
          qsetwhere: {
            AND: [
              {
                QApp: {
                  id: {
                    equals: appid,
                  },
                },
              },
              {
                userId: {
                  equals: user?.id,
                },
              },
            ],
          },
        },
      });
    }
  }, [appid, getMyApp, getQAppLevelStatus, loadingCallback, user.id]);

  const continuePreviousTestOrcreateQset = () => {
    if (currentQSet?.id) {
      history.push(
        `/qviewer/?appid=${appid}&userid=${userid}&qsetid=${currentQSet?.id}`,
      );
      return true;
    }
    if (!selectedLevel) {
      toast({
        position: "top",
        title: "Please select level to generate the question set.",
        status: "info",
        duration: 4000,
      });
      return true;
    }
    loadingCallback(true, "generating question sets");
    generateQSet({
      variables: {
        data: {
          id: appid,
          userId: user.id,
          dynoType: "Practice",
          level: selectedLevel,
        },
      },
    });
  };

  const { getRootProps, getRadioProps, setValue } = useRadioGroup({
    name: "level",
    onChange: (value) => setSelectedLevel(value),
  });

  const group = getRootProps();

  return {
    myApp,
    qsets,
    currentQSet,
    myBadges,
    levels,
    selectedLevel,
    appid,
    userid,
    history,
    group,
    qAppLevelStatus,
    isLoading,
    getRadioProps,
    continuePreviousTestOrcreateQset,
  };
};
