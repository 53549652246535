import React from "react";
import { Box } from "@chakra-ui/react";
import loadable from "@loadable/component";
import { QViewerHeader } from "../QViewerHeader";
import "./style.scss";
const DynosViewerComponent = loadable(
  () => import("@greenon/dynos.viewer.component"),
);

export const Viewer = ({
  selectedDyno,
  requestStores,
  userInfo,
  submissionCallback,
  isLastDyno,
  progressPercentage,
  lastAttemptedDynoId,
  qSetId,
  isLastDynoOfQSet,
}) => {
  return (
    <Box>
      {requestStores.constants && selectedDyno && userInfo && (
        <Box
          className={`${
            isLastDyno &&
            progressPercentage !== 100 &&
            selectedDyno.myDyno.info.dyno_type === "Practice"
              ? "submit-btn-pos"
              : ""
          }`}
        >
          <DynosViewerComponent
            isProductViewer={true} //Required
            dynoData={selectedDyno}
            stores={requestStores}
            callerType="QSetViewer"
            qSetId={qSetId}
            lastAttemptedDynoId={lastAttemptedDynoId}
            userInfo={userInfo}
            submissionCallback={submissionCallback}
            isLastDyno={isLastDynoOfQSet}
          />
        </Box>
      )}
    </Box>
  );
};
