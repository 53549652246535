import React from "react";
import {
  Box,
  Text,
  Heading,
  Button,
  Flex,
  Grid,
  List,
  ListItem,
  IconButton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tooltip,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { Card } from "../../components/Card";
import { BadgeImage } from "../../components/BadgeImage";
import { PracticeIcon } from "../../components/Icons/PracticeIcon";
import { BadgesIcon } from "../../components/Icons/BadgesIcon";
import { useHome } from "./useHome";
import { LevelCard } from "../../components/LevelCard";

export const HomePage = ({ user, userRole, loadingCallback }) => {
  const {
    myApp,
    qsets,
    currentQSet,
    myBadges,
    levels,
    selectedLevel,
    appid,
    userid,
    history,
    group,
    qAppLevelStatus,
    isLoading,
    getRadioProps,
    continuePreviousTestOrcreateQset,
  } = useHome({ user, loadingCallback });
  const appName = sessionStorage.getItem("appName");
  return (
    <Box padding={{ base: "20px 10px", md: "20px 20px" }}>
      {/* <Text>{myApp?.app?.bundleDescription}</Text> */}
      {userRole === "Student" && (
        <Box
          sx={{
            margin: "30px auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Heading
            as="h1"
            sx={{ fontSize: "24px", color: "rgb(34, 63, 153)" }}
            textAlign="center"
          >
            {appName}
          </Heading>
          {!isLoading && (
            <Flex
              {...group}
              sx={{
                margin: "30px 0",
                display: "flex",
                flexDirection: { sm: "unset", md: "row" },
                flexWrap: { sm: "wrap" },
                alignItems: { sm: "center" },
                justifyContent: { sm: "center" },
              }}
            >
              {React.Children.toArray(
                levels.map((level, index) => {
                  const radio = getRadioProps({
                    value: level.name,
                    isDisabled: !myApp?.levelStatus[index],
                  });
                  return (
                    <LevelCard {...radio} criteria={level.criteria}>
                      {level.name}
                    </LevelCard>
                  );
                }),
              )}
            </Flex>
          )}
          {
            qAppLevelStatus[selectedLevel] ?
              <Tooltip hasArrow label={myApp?.levelStatus[parseInt(selectedLevel.match(/\d+/)[0], 10)] ? "" : `You have exhausted ${selectedLevel}'s quota for generating new questions. Please answer total ${levels.length > 0 && selectedLevel !== "" && levels.find(l => l.name === selectedLevel.replace(/\d+/, (n) => +n + 1)).criteria} questions correctly to unlock next level. You can try again the questions.`} bg='red.600'>
                <Button
                  onClick={(e) => e.preventDefault()}
                  className="disable-blinking-button"
                >
                  Generate Question Set
                </Button>
              </Tooltip> :
              <Button
                colorScheme="brand"
                onClick={continuePreviousTestOrcreateQset}
                className="blinking-button"
              >
                {currentQSet?.id
                  ? "Continue Previous Test"
                  : "Generate Question Set"}
              </Button>
          }
        </Box>
      )}
      <Grid
        sx={{
          gridTemplateColumns: {
            sm: "repeat(1, 1fr)",
            md: "repeat(1, 1fr)",
          },
          gap: {
            sm: "4",
            md: "6",
          },
          margin: "0 auto 30px",
        }}
      >
        <StoryCard
          title="Practice Tests"
          icon={<PracticeIcon/>}
          body={
            <Box overflowX="auto">
              <LastThreeQsets
                userRole={userRole}
                qsets={qsets}
                appid={appid}
                userid={userid}
                history={history}
                practiceQset={(id, status) => {
                  sessionStorage.setItem("fromPath", `/?appid=${appid}&userid=${userid}`)
                  history.push(
                    status === "View Responses" ? `/qreview/?appid=${appid}&userid=${userid}&qsetid=${id}` : `/qviewer/?appid=${appid}&userid=${userid}&qsetid=${id}`,
                  )
                }}
              />
              {qsets?.length === 0 && <Text textAlign="center" padding="20px 0">No previous tests to show!</Text>}
            </Box>
          }
          link={() =>
            history.push(`/previoustests?appid=${appid}&userid=${userid}`)
          }
        />
        <StoryCard
          title="Badges"
          icon={<BadgesIcon />}
          body={
            <Box sx={{ padding: "1.25rem" }} background="#fff" borderRadius="20px">
              <List>
                {React.Children.toArray(
                  myBadges?.map((badge) => (
                    <ListItem borderBottom="1px solid rgba(131, 90, 238,0.12)">
                      <Flex
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                          margin: "10px 0",
                        }}
                      >
                        <Box
                          sx={{width: "20%"}}
                        >
                          <BadgeImage
                            src={badge?.badgeImage}
                            alt={badge.badgeName}
                            isLocked={badge.isLocked}
                          />
                        </Box>
                        <Text sx={{ width: "30%", padding: "10px" }} color={badge.isLocked ? "#000" : "#00b0ff"} fontWeight={badge.isLocked ? "" : 700}>
                          {badge.badgeName}
                        </Text>
                        <Text sx={{ width: "50%", fontSize: "md" }} color={badge.isLocked ? "#000" : "#00b0ff"} fontWeight={badge.isLocked ? "" : 700}>
                          {badge.isLocked
                            ? `Unlock this badge when ${badge.criteria} questions answered correctly.`
                            : `Congratulations! 🎉 You've unlocked the ${badge.badgeName} badge!`}
                        </Text>
                      </Flex>
                    </ListItem>
                  )),
                )}
              </List>
            </Box>
          }
          link=""
        />
      </Grid>
    </Box>
  );
};

const StoryCard = ({ title, body, link, icon }) => {
  return (
    <Card
      header={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            // borderBottom: "solid 1px rgba(34, 63, 153, 0.1)",
            padding: "1rem",
            svg: {
              width: "32px",
              height: "32px",
            },
            background: "rgba(131, 90, 238, 0.10)",
            borderTopRightRadius: "20px",
            borderTopLeftRadius: "20px",
          }}
        >
          <Heading size="md" sx={{ display: "flex", alignItems: "center" }}>
            {icon}
            <Box as="span" marginLeft= "5px">{title}</Box>
          </Heading>
          {!!link && (
            <IconButton
              variant="ghost"
              colorScheme="brand"
              icon={<ArrowForwardIcon />}
              fontSize="1.5rem"
              onClick={link}
            />
          )}
        </Box>
      }
      body={body}
    />
  );
};

const LastThreeQsets = ({ qsets, practiceQset, userRole, appid, userid, history }) => {
  return (
    <Table background="#fff" borderRadius="20px" minWidth="1000px">
      <Thead>
        <Tr>
          <Th color= "rgb(131, 90, 238)" borderColor="rgba(131, 90, 238,0.12)">Name</Th>
          <Th color= "rgb(131, 90, 238)" borderColor="rgba(131, 90, 238,0.12)">Date Of Test</Th>
          <Th color= "rgb(131, 90, 238)" borderColor="rgba(131, 90, 238,0.12)">Score</Th>
          <Th color= "rgb(131, 90, 238)" borderColor="rgba(131, 90, 238,0.12)">Total Time Taken</Th>
          <Th color= "rgb(131, 90, 238)" borderColor="rgba(131, 90, 238,0.12)">Avg Time Per Question</Th>
          {userRole === "Student" && <Th>&nbsp;</Th>}
          {userRole === "Student" && <Th>&nbsp;</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {React.Children.toArray(
          qsets.map((qset) => (
            <Tr>
              <Td borderColor="rgba(131, 90, 238,0.12)">{qset.name}</Td>
              <Td borderColor="rgba(131, 90, 238,0.12)">{qset.submittedAt ? qset.submittedAt : "-"}</Td>
              <Td color="#29b473" fontWeight="700" fontSize= "18px" borderColor="rgba(131, 90, 238,0.12)">{qset.score ? qset.score : "-"}</Td>
              <Td borderColor="rgba(131, 90, 238,0.12)">{qset.totalTimeTaken ? qset.totalTimeTaken : "-"}</Td>
              <Td borderColor="rgba(131, 90, 238,0.12)">{qset.avgTimeTaken ? qset.avgTimeTaken : "-"}</Td>
              {userRole === "Student" && (
                <Td>
                  {<Button
                    variant="ghost"
                    colorScheme={qset.status === "Continue" ? "yellow" : "brand"}
                    aria-label="practice"
                    onClick={() => practiceQset(qset.id, qset.status)}
                    rightIcon={<ArrowForwardIcon />}
                    textTransform={qset.status === "Continue" ? "uppercase" : "capitalize"}
                  >
                    {qset.status}
                  </Button>}
                </Td>
              )}
              {
                userRole === "Student" && qset.status === "View Responses" ?
                  <Td>
                    <Button
                      variant="ghost"
                      colorScheme="brand"
                      aria-label="practice"
                      onClick={() => history.push(`/qviewer/?appid=${appid}&userid=${userid}&qsetid=${qset.id}`)}
                      rightIcon={<ArrowForwardIcon />}
                    >
                      Try Again
                    </Button>
                  </Td> : <Td></Td>
              }
            </Tr>
          )),
        )}
      </Tbody>
    </Table>
  );
};
