import React from "react";
import { Box, Image, Tooltip, useRadio } from "@chakra-ui/react";

export const LevelCard = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);
  const input = getInputProps();
  const checkbox = getCheckboxProps();
  return (
    <Box as="label" sx={{ position: "relative" }}>
      <input {...input} />
      <Tooltip
        label={
          input.disabled
            ? `Unlock this level when ${props.criteria} questions answered correctly on the previous level.` : ""
        }
        aria-label="level criteria"
      >
        <Box
          {...checkbox}
          disabled={input.disabled}
          cursor="pointer"
          borderWidth="1px"
          boxShadow="md"
          _checked={{
            color: "#29b473",
            borderColor: "#29b473",
            borderBottomLeftRadius: "5px",
            borderTopLeftRadius: "5px",
            background: "rgba(41,180,115,0.12)",
            fontWeight: 700,
          }}
          px={5}
          py={3}
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: input.disabled ? "not-allowed" : "pointer",
            opacity: input.disabled ? "0.6" : "1",
            overflow: "hidden",
          }}
        >
          {input.disabled && (
            <Image
              src="./images/lock.png"
              alt="badge locked"
              sx={{
                width: "18px",
              }}
            />
          )}
          <Box as="span">{props.children}</Box>
        </Box>
      </Tooltip>
    </Box>
  );
};
