import React, { useEffect } from 'react';

const loadMathJax = () => {
  const script = document.createElement('script');
  script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js';
  script.async = true;
  document.head.appendChild(script);
};

const useMathJax = (mathData, id) => {
  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise([document.getElementById(`response${id}`)]).then(() => {
        console.log('MathJax typesetting complete');
      });
    }
  }, [mathData]);
};

const MathJax = ({ mathData, id, className }) => {
  useEffect(() => {
    if (mathData) {
      loadMathJax();
    }
  }, [mathData]);

  useMathJax(mathData, id);

  return (
    <>
      <div className={className} dangerouslySetInnerHTML={{ __html: mathData }} id={`response${id}`} />
    </>
  );
};

export default MathJax;
