import { gql } from "@apollo/client";

export const GENERATEQSET = gql`
  mutation GenerateQSet($data: GenerateQSetsWhereInput!) {
    generateQSet(data: $data) {
      id
      learningMode
      isTimed
      dynosUnderQSet {
        id
      }
      createdBy {
        id
        firstName
      }
    }
  }
`;
export const SENDFEEDBACKFORBADRESPONSE = gql`
  mutation ViewerErrorReport($data: ViewerErrorReportInput!) {
    viewerErrorReport(data: $data)
  }
`;
