import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import Amplify from "aws-amplify";
import { awsconfig } from "./utils/aws-config";
import { getCookie } from "./utils/cookie";
import reportWebVitals from "./reportWebVitals";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ADMIN_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from cookies if it exists
  const token = getCookie("ninja_session");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-access-token": token,
    },
  };
});

Amplify.configure({ Auth: awsconfig });

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
