export const backendPoint = process.env.REACT_APP_ADMIN_APP_GRAPHQL_ENDPOINT;
export const userpoolId = process.env.REACT_APP_USER_POOL_ID;
export const userpoolClientId = process.env.REACT_APP_USER_POOL_CLIENT_ID;
export const cognitoRegionName = process.env.REACT_APP_COGNITO_REGION_NAME;
export const appDomain = process.env.REACT_APP_APP_DOMAIN;
export const productViewerUrl = process.env.REACT_APP_VIEWER_URL;
export const b2cAppUrl = process.env.REACT_APP_B2C_URL;
export const userpoolIdIn = process.env.REACT_APP_USER_POOL_ID_IN;
export const userpoolClientIdIn = process.env.REACT_APP_USER_POOL_CLIENT_ID_IN;
export const cognitoRegionNameIn = process.env.REACT_APP_COGNITO_REGION_NAME_IN;
export const india = process.env.REACT_APP_REGION;
