import React from 'react'
import { 
    Box, 
    Flex, 
    Text, 
    CloseButton, 
    Button 
  } from "@chakra-ui/react";
import "./Modal.scss"

const CustomModal = ({children,openModal,closeModal,heading, submitFeedback, feedbackResponse}) => {
    
    return (
        <React.Fragment>
            {
                openModal && 
                <Box className="modal--wrap">
                    {/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */}
                    <Box onClick={closeModal} role="button" aria-label="overlay" className="modal--overlay"></Box>
                    <Box className="modal--content">
                        {
                            feedbackResponse.sent ?
                            <Box textAlign={"center"}>
                                <Text color={`${feedbackResponse.success ? "green": "red"}`}>
                                    {feedbackResponse.message}
                                </Text>
                            </Box>
                             : 
                             <>
                                <Box className="modal--body">
                                    <Flex justifyContent={"space-between"} alignItems={"center"} marginBottom="10px">
                                        <Text as='b' fontSize='20px'>{heading}</Text>
                                        <CloseButton size='md' onClick={closeModal}/>
                                    </Flex>
                                    {children}
                                </Box>
                                <Box className="modal--footer">
                                    <Button onClick={submitFeedback} className="modal--confirm">Submit</Button>
                                    <Button onClick={closeModal} className="modal--cancel">Cancel</Button>
                                </Box>
                             </>
                        }
                    </Box>
                </Box>
            }
            
        </React.Fragment> 
    )
}
export default CustomModal 
