import React from "react";
import {
  Box,
  Menu,
  MenuButton,
  Flex,
  Avatar,
  Button,
  Image
} from "@chakra-ui/react";
import { useLocation, useHistory } from "react-router-dom";
import { LoginButton } from "../LoginButton";


const UserMenu = ({ firstName, lastName, userPicture }) => {
  return (
    <Menu>
      <MenuButton>
        <Avatar size="md" name={`${firstName} ${lastName}`} src={userPicture} />
      </MenuButton>
    </Menu>
  );
};

export const Header = ({ isAuthenticated, user }) => {
  const { search, pathname } = useLocation();
  const queryParams = new URLSearchParams(search);
  const appid = queryParams.get("appid");
  const userid = queryParams.get("userid");
  const history = useHistory();
  return (
    <Box
      as="header"
      sx={{
        position: "sticky",
        top: "0",
        background: "#fff",
        zIndex: 999,
        display: "block",
        boxShadow: "0px 15px 10px -15px rgba(0, 176, 255, 0.16)",
        border: "none"
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="2"
        width="100%"
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="calc(100% - 68px)"
        >
          <Flex
            sx={{
              alignItems: "center",
            }}
          >
            <Button 
              sx={{
                padding: 0,
                margin: 0,
                ":hover": {
                  outline: "none",
                  outlineOffset: 0,
                  boxShadow: "none",
                  background: "transparent"
                },
                ":focus": {
                  outline: "none",
                  outlineOffset: 0,
                  boxShadow: "none",
                  background: "transparent"
                },
                ":active": {
                  outline: "none",
                  outlineOffset: 0,
                  boxShadow: "none",
                  background: "transparent"
                }
              }}
              variant="ghost" 
              onClick={() => history.replace(`/?appid=${appid}&userid=${userid}`)}
            >
              <Image src='/images/logo.png' sx={{width: {sm: "80px", md: "80px"}, padding: "0 10px", boxSizing: "content-box"}} alt='logo' />
            </Button>
          </Flex>
          <Button
            variant="outline"
            colorScheme="brand"
            onClick={() =>
              history.push(`/report?appid=${appid}&userid=${userid}`)
            }
          >
            View Reports
          </Button>
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          {isAuthenticated && user ? (
            <UserMenu
              firstName={user.firstName}
              lastName={user.lastName}
              userPicture={user.userPicture}
            />
          ) : (
            <LoginButton styleProps={{ variant: "outline" }} />
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
