import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import { Viewer } from "../../components/Viewer";
import { useQViewer } from "./useQViewer";
import { Timer } from "../../components/Timer";

export const QViewerPage = ({ user, loadingCallback, loading }) => {
  const {
    selectedDyno,
    qSetId,
    requestStores,
    lastAttemptedDynoId,
    submissionCallback,
    dynoLoading,
    currentQSetDynoIndex,
    isLastDynoOfQSet,
  } = useQViewer({ user, loadingCallback });
  return (
    <Box>
      <Flex justifyContent="space-between" width="100%" background={"#fff"}>
        {dynoLoading && (
          <Box className="overlay-loader">
            <Image src="/images/ninja.gif" width="100px" height= "100px"/>
          </Box>
        )}
        <Flex width="100%" className="quiz-page" alignItems={"flex-start"} backgroundColor= "#f7fafb">
          {!dynoLoading && (
            <Box
              className={
                selectedDyno.myDyno &&
                selectedDyno.myDyno.info.dyno_type === "Practice"
                  ? "practice-dyno"
                  : "common-dyno"
              }
            >
              {selectedDyno?.myDyno?._id && (
                <Viewer
                  selectedDyno={selectedDyno}
                  requestStores={requestStores}
                  userInfo={user}
                  submissionCallback={submissionCallback}
                  lastAttemptedDynoId={lastAttemptedDynoId}
                  qSetId={qSetId}
                  isLastDynoOfQSet={isLastDynoOfQSet}
                />
              )}
            </Box>
          )}
          <Box className="timer-chat-box">
            <Box className="timer-chat-wrap">
              {!loading && !dynoLoading && 
                <>
                  <Box className="info-container align-start">
                    <Box className="info-wrapper flex-center padding-10">
                      <Box className="time-elapsed">
                        Time elapsed <Timer />
                      </Box>
                      <Box>
                        Question no.{" "}
                        <span
                          style={{
                            fontSize: "30px",
                            color: "#00b0ff",
                            fontWeight: 600,
                          }}
                        >
                          {currentQSetDynoIndex}
                        </span>
                        /10
                      </Box>
                    </Box>
                  </Box>
                </>
              }
            </Box>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};
