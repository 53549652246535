import React from "react";
import { Box } from "@chakra-ui/react";
import "./Card.scss"


export const Card = ({ header, body }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minWidth: "0px",
        overflowWrap: "break-word",
        backgroundColor: "#fff",
        color: "#1A202C",
        borderWidth: "0",
        borderRadius: "20px",
        boxShadow: "rgba(10, 94, 255, .12) 0px 1px 2px 0px, rgba(10, 94, 255, .12) 0px 2px 6px 2px"
      }}
      className="card-wrap"
    >
      <>{header}</>
      <>{body}</>
    </Box>
  );
};
