import React from "react";
import { Box, Flex, Button, Image } from "@chakra-ui/react";

export const QSetHeader = ({ onChangeDyno, navigateToHome, disableNext, disablePrev }) => {
  return (
    <Box
      as="header"
      sx={{
        position: "sticky",
        top: "64px",
        background: "#f7fafb",
        zIndex: 1,
        display: "block",
        border:"none"
      }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        padding="2"
        height="64px"
        width="100%"
      >
        <Button
          colorScheme="blue"
          variant="ghost"
          fontSize="18px"
          onClick={() => onChangeDyno(false)}
          isDisabled={disablePrev}
        >
          <Image src="/images/left-arrow.png" alt="Previous" />
          Previous
        </Button>
        {!disableNext ? <Button
          colorScheme="blue"
          variant="ghost"
          fontSize="18px"
          onClick={() => onChangeDyno(true)}
          isDisabled={disableNext}
        >
          Next
          <Image src="/images/right-arrow.png" alt="Previous" />
        </Button> : <Button
          colorScheme="blue"
          variant="ghost"
          fontSize="18px"
          onClick={navigateToHome}
        >
          <Image src="/images/home.png" alt="home" />
          <Box as="span" padding="0 0 0 5px">Home</Box>
        </Button>}
      </Flex>
    </Box>
  );
};
