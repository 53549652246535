import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalCloseButton,
    ModalFooter,
    Button,
    Image,
    Box,
    Text
  } from '@chakra-ui/react'
import { Confetti } from "../Confetti";

export const BadgeModal = ({isOpen, onClose, badge}) => {
    return (
        <Box>
            <Modal onClose={onClose} size={'full'} isOpen={isOpen} background="transparent">
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(20px)'
                />
                <ModalContent backgroundColor="transparent">
                    <ModalCloseButton zIndex="5"/>
                    <ModalBody sx={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "30px", backgroundColor: "transparent"}}>
                        <Confetti />
                        <Text style={{fontSize: "22px", fontWeight: 500}} className="blinking-text">You have unlocked a new badge.</Text>
                        <Image src={badge.badgeImage} alt="badge" style={{margin: "50px 0 10px", width: "120px", height: "120px"}} />
                        <Text style={{fontSize: "32px", fontWeight: 700, color: "#5f2027"}}>{badge.badgeName}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClose}>Ok</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};
